import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'

import {apiTaskInfoDetail} from "../../common/api/task/info/apiTaskInfoDetail";
import {TaskInterface} from "../../common/interfaces/TaskInterface";
import {formatScanCode} from "../../common/utils/formatScanCode";
import {apiTaskItemDetailByCode} from "../../common/api/task/item/apiTaskItemDetailByCode";
import {ItemInterface} from "../../common/interfaces/ItemInterface";
import {apiTaskInfoUpdateStatus} from "../../common/api/task/info/apiTaskInfoUpdateStatus";
import {apiTaskItemLinkAll} from "../../common/api/task/item/apiTaskItemLinkAll";
import {apiTaskInfoAssign} from "../../common/api/task/info/apiTaskInfoAssign";
import {CategoryToScanInterface} from "../../common/interfaces/CategoryToScanInterface";
import {mapCategoriesToScan} from "../../common/utils/mapCategoriesToScan";
import {deliveryTypeEnum} from "../../common/enums/deliveryTypeEnum";
import {apiTaskItemUnlinkAll} from "../../common/api/task/item/apiTaskItemUnlinkAll";
import {apiTaskItemDetail} from "../../common/api/task/item/apiTaskItemDetail";
import {taskEnableCategoriesToScan} from "../../common/utils/taskEnableCategoriesToScan";
import {apiTaskInfoDownloadPdf} from "../../common/api/task/info/apiTaskInfoDownloadPdf";
import {apiTaskInfoUnAssign} from "../../common/api/task/info/apiTaskInfoUnAssign";

interface taskStateInterface {
  example?: string,
  loading?: boolean,
  loaded?: boolean,
  task?: TaskInterface,
  scannedItems: ItemInterface[],
  selectedUserId?: number,
  categoriesToScan: CategoryToScanInterface[],
  updateTask: {
    updating: boolean,
    updated: boolean
  },
  downloadPdf: {
    loading: boolean,
    url?: string
  }
}

const initialState: taskStateInterface = {
  scannedItems: [],
  categoriesToScan: [],
  loaded: false,
  updateTask: {
    updating: false,
    updated: false
  },
  downloadPdf: {
    loading: true
  }
}

export interface UpdateTaskInterface {
  id: number,
  status: string,
}


export const updateTaskStatus = createAsyncThunk(
  'root/updateTaskStatus',
  async (updateTask: UpdateTaskInterface, {getState}) => {

    const {taskDetail} = getState() as { taskDetail: taskStateInterface };

    if (taskDetail.task) {

      if (taskDetail.categoriesToScan) {

        const itemIds = taskDetail.categoriesToScan.flatMap((categoryToScan) => {
          if (categoryToScan.scanned && categoryToScan.item) {
            return categoryToScan.item.id
          } else return []
        })

        if (taskDetail.task && taskEnableCategoriesToScan(taskDetail.task)) {
          await apiTaskItemLinkAll(taskDetail.task.id, itemIds)
        }

        if (taskDetail.task.status.code === 'delivered' && taskDetail.task.deliveryType?.code === deliveryTypeEnum.PICKUP) {
          await apiTaskItemUnlinkAll(itemIds)
        }
      }
    }

    if (taskDetail.selectedUserId) {
      await apiTaskInfoAssign(taskDetail.selectedUserId, [updateTask.id])
    }

    return await apiTaskInfoUpdateStatus(updateTask.id, updateTask.status)
  }
)

export const updateTaskUser = createAsyncThunk(
  'root/updateTaskUser',
  async (userId: number, {getState}) => {

    const {taskDetail} = getState() as { taskDetail: taskStateInterface };

    if (userId && taskDetail.task) {
      await apiTaskInfoAssign(userId, [taskDetail.task?.id])
    }

    return userId
  }
)

export const resetTaskUser = createAsyncThunk(
  'root/resetTaskUser',
  async (_, {getState}) => {

    const {taskDetail} = getState() as { taskDetail: taskStateInterface };

    if (taskDetail.task) {
      await apiTaskInfoUnAssign([taskDetail.task?.id])
    }

    return true
  }
)


export const load = createAsyncThunk(
  'task/load',
  async (id: number) => {

    const response = await apiTaskInfoDetail(id)
    return response.data.items


  }
)

export const downloadPdf = createAsyncThunk(
  'task/downloadPdf',
  async (id: number) => {
    const response = await apiTaskInfoDownloadPdf(id)
    return response.data.items
  }
)

export const scan = createAsyncThunk(
  'task/scan',
  async (code: string, {getState}) => {

    const scanCodeResult = formatScanCode(code)
    let response
    if (scanCodeResult.type === 'id') {
      response = await apiTaskItemDetail(scanCodeResult.code)
    } else {
      response = await apiTaskItemDetailByCode(scanCodeResult.code)
    }

    if (response.data.items) {

      const {taskDetail} = getState() as { taskDetail: taskStateInterface };
      const scannedItem = response.data.items as ItemInterface

      if (taskDetail.task && taskEnableCategoriesToScan(taskDetail.task)) {
        if (taskDetail.task.categories?.find((taskCategory) => (
            (taskCategory.category.id === scannedItem.availabilityCategory.id) ||
            (taskCategory.category.id === scannedItem.category.id)
        ))) {
          return scannedItem
        }
      } else {
        if (taskDetail.task?.items?.find((item) => item.productCode === scannedItem?.productCode)) {
          return scannedItem
        }
      }
    }
  }
)

const TaskDetailSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(updateTaskStatus.pending, (state) => {
      state.updateTask.updated = false
      state.updateTask.updating = true
    })
    builder.addCase(updateTaskStatus.fulfilled, (state) => {
      state.updateTask.updated = true
      state.updateTask.updating = false
      state.loaded = false
    })
    builder.addCase(updateTaskUser.fulfilled, (state, action: PayloadAction<number>) => {
      state.selectedUserId = action.payload
    })
    builder.addCase(resetTaskUser.fulfilled, (state) => {
      delete state.selectedUserId
    })
    builder.addCase(downloadPdf.fulfilled, (state, action: PayloadAction<any>) => {
      state.downloadPdf.loading = false
      state.downloadPdf.url = action.payload.url
    })
    builder.addCase(load.pending, (state) => {
      state.loading = true
      state.loaded = false
    })
    builder.addCase(load.fulfilled, (state, action: PayloadAction<TaskInterface>) => {
      state.loading = false
      state.loaded = true
      state.task = action.payload

      if (action.payload.user) {
        state.selectedUserId = action.payload.user.id
      }

      state.categoriesToScan = []

      state.categoriesToScan = mapCategoriesToScan(action.payload, state.categoriesToScan)
    })
    builder.addCase(scan.fulfilled, (state, action: PayloadAction<any>) => {
      if (action.payload) {

        const scannedItem = action.payload as ItemInterface
        const categoryToScanIndex = state.categoriesToScan.findIndex((categoryToScan) => categoryToScan.item && categoryToScan.item.id === scannedItem.id)

        if (categoryToScanIndex !== -1) {

          state.categoriesToScan = state.categoriesToScan.map((categoryToScan, index) => {
            if (index === categoryToScanIndex) {
              if (categoryToScan.persistent) {

                // Keep item switch scanned status
                return {
                  ...categoryToScan,
                  scanned: !categoryToScan.scanned
                }
              } else {
                // Unset item
                return {
                  category: categoryToScan.category,
                  scanned: false
                }
              }
            } else return categoryToScan
          })

        } else {
          const emptyCategoryToScanIndex = state.categoriesToScan.findIndex((categoryToScan) => (
              categoryToScan.category?.id === scannedItem.availabilityCategory?.id ||
              categoryToScan.category?.id === scannedItem.category?.id
          ) && !categoryToScan.item)

          if (emptyCategoryToScanIndex !== -1) {
            state.categoriesToScan = state.categoriesToScan.map((categoryToScan, index) => {
              if (index === emptyCategoryToScanIndex) {
                return {
                  ...categoryToScan,
                  item: scannedItem,
                  scanned: true
                }
              } else return categoryToScan
            })
          }
        }
      }
    })
  },
})

export const {reset} = TaskDetailSlice.actions
export const taskDetailReducer = TaskDetailSlice.reducer
